import i18n from '@/i18n.js';
import router from "@/router";
import PermitService from '../services/temp-permit.service';
export const temp_permit = {
  namespaced: true,
  state: () => ({
    order: null,
    camp: null,
    polygon: null,
    form_data: null,
    form_fields: [],
    answer_default: true,
  }),
  mutations: {
    SET_DETAILS(state, location) {
      state.camp = location;
    },
    SET_POLYGON(state, polygon) {
      state.polygon = polygon;
    },
    SET_LOCATION_FORM(state, formData) {
      state.form_data = formData;
    },
    SET_FORM_FIELDS(state, formField) {
      let field = state.form_fields.find((el) => el.id == formField.id);
      if (field != undefined) {
        field.value = formField.value;
      }
      else {
        state.form_fields.push(formField);
      }
    },
    SET_ANSWER_DEFAULT(state, changed) {
      state.answer_default = changed;
    },
    SET_FORM_DATA(state, data) {
      state.form_data = data;
    }
  },
  getters: {
    getSelectedDetails(state) {
      return state.camp;
    },
    getDrawnPolygon(state) {
      return state.polygon;
    },
    getOrder(state) {
      return state.order;
    },
    getFormData(state) {
      return state.form_data;
    },
    getAnswerDefault(state) {
      return state.answer_default;
    },
  },
  actions: {
    approve_order({ commit, rootState, state }) {
      let postData = new FormData();
      if (state.form_data != null) {
        postData = state.form_data;
      }
      postData.append("order_id", rootState.id);
      postData.append("form_id", state.camp.form_id);
      // if (state.answer_default == false) {
      return PermitService.approve_order(postData).then((response) => {
        rootState.form.notify = {
          msg: response.data.message,
          type: "Success",
        };
        setTimeout(function () {
          router.push(`/temp-permit-orders`);
        }, 1000);
        return Promise.resolve(response)
      },
        (error) => {
          console.log(error);
          rootState.form.loader = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            for (let i = 0; i < rootState.form.style_form.length; i++) {
              const element = rootState.form.style_form[i];
              element.error = errors[element.value_text];
            }
          } else if (error.response.status != 401) {
            rootState.form.notify = {
              msg: error.response.data.message,
              type: "Danger",
            };
          }
          return Promise.reject(error)
        })
      // }
    },
    update_form({ commit, rootState, state }) {
      let postData = new FormData();
      if (state.form_data != null) {
        postData = state.form_data;
      }
      postData.append("order_id", rootState.id);
      postData.append("form_id", state.camp.form_id);
      console.log("postData:", ...postData)
      // if (state.answer_default == false) {
      return PermitService.update_form(postData).then((response) => {
        rootState.form.notify = {
          msg: response.data.message,
          type: "Success",
        };
        setTimeout(function () {
          router.push(`/temp-permit-orders`);
        }, 1000);
        return Promise.resolve(response)
      },
        (error) => {
          console.log(error);
          rootState.form.loader = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            for (let i = 0; i < rootState.form.style_form.length; i++) {
              const element = rootState.form.style_form[i];
              element.error = errors[element.value_text];
            }
          } else if (error.response.status != 401) {
            rootState.form.notify = {
              msg: error.response.data.message,
              type: "Danger",
            };
          }
          return Promise.reject(error)
        })
      // }
    },


    sign_order({ rootState }, data) {
      return PermitService.sign_order(data).then(
        (response) => {
          rootState.table.items.map(v => {
            if (v.id == data.id) {
              v.status = response.data.data.status
              return v;
            }
          });
          return Promise.resolve(response);
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        }
      );
    },
    process_location({ commit, rootState }, data) {
      data.append("id", rootState.id);
      return PermitService.reject_order(data).then(
        (response) => {
          rootState.form.loader = false;
          rootState.form.dialog = false;
          rootState.form.style_form = [];
          rootState.form.notify = {
            msg: response.data.message,
            type: "Success",
          };

          setTimeout(function () {
            router.push(`/temp-permit-orders`);
          }, 1000);
        },
        (error) => {
          console.log(error);
          rootState.form.loader = false;
          if (error.response.status == 422) {
            var errors = error.response.data.errors;
            for (let i = 0; i < rootState.form.style_form.length; i++) {
              const element = rootState.form.style_form[i];
              element.error = errors[element.value_text];
            }
          } else if (error.response.status != 401) {
            rootState.form.notify = {
              msg: error.response.data.message,
              type: "Danger",
            };
          }
        }
      );
    },
    post_polygon({ state }) {
      let polygon = { geo: state.polygon };

      PermitService.store_order(polygon).then((response) => {
        state.order = response.data.data;
      });
    },
    post_selected({ rootState, state }) {
      let selected = { location_id: state.camp.id };
      PermitService.store_order(selected).then((response) => {
        state.order = response.data.data;
        rootState.form.notify = {
          msg: "تم قبول الموقع",
          type: "Success",
        };
      });
    },
    // pagination section
    handlePageChange({ rootState, dispatch }, page) {
      rootState.table.paginate.page = page;
      dispatch("getData", {});
    },

    changeItemPage({ rootState, dispatch }, pre_page) {
      rootState.table.paginate.itemsPerPage = parseInt(pre_page);
      rootState.table.paginate.page = 1;
      dispatch("getData", { pre_page, type: "pre_page" });
    },

    // filter section
    filterData({ dispatch, rootState }) {
      rootState.table.paginate.page = 1;
      dispatch("getData", {});
    },
    resetFilter({ rootState, dispatch }) {
      rootState.table.paginate.page = 1;
      dispatch("getData", { reset: true });
    },

    getData({ rootState }, data) {
      rootState.table.loading = true;
      const formData = new FormData();
      if (!data.hasOwnProperty("reset")) {
        rootState.form.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (data.hasOwnProperty("type") && data.type == "pre_page") {
        formData.append("paginate", data.pre_page);
      } else {
        if (rootState.table.paginate.itemsPerPage != "")
          formData.append("paginate", rootState.table.paginate.itemsPerPage);
      }
      return PermitService.get(rootState.table.paginate.page, formData).then(
        (response) => {
          rootState.table.items = response.data.data.data;
          rootState.table.paginate = {
            total: response.data.data.total,
            itemsPerPage: parseInt(response.data.data.per_page),
            row_pre_page: true,
            page: rootState.table.paginate.page,
          };
          if (data.hasOwnProperty("reset") && data.reset) {
            rootState.form.filters = response.data.filters;
          }
          rootState.table.loading = false;
          return Promise.resolve(response);
        },
        (error) => {
          console.log(error);
          rootState.table.loading = false;
          if (error.response.status != 401) {
            rootState.form.notify = {
              msg: i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
          return Promise.reject(error);
        }
      );
    },
  },
};